import * as React from "react"
import { Suspense, useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "next-i18next"
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material"
import { au_border_radius_accentuated } from "./utils/themeConstants"
import { X } from "react-feather"

export interface AUDialogProps {
  open: boolean
  title: string
  closeLabel?: string
  acceptLable?: string
  icon: JSX.Element
  children: React.ReactNode
  dialogWidth?: string
  onClose: () => void
  onAccept?: () => void
}

const AUDialog = ({
  open,
  title,
  closeLabel,
  acceptLable,
  icon,
  children,
  dialogWidth = "400px",
  onClose,
  onAccept,
}: AUDialogProps) => {
  const { t } = useTranslation()

  useEffect(() => {}, [])

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: dialogWidth,
          padding: "4px",
        }}
      >
        <Card
          sx={{
            minWidth: 250,
            minHeight: 150,
            color: "#000",
            borderRadius: au_border_radius_accentuated,
          }}
        >
          <div
            style={{
              background: "#fff",
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              position: "fixed",
              top: "-17px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              {icon}
            </div>
          </div>
          <Grid
            container
            direction="row"
            alignItems="stretch"
            justifyContent={"space-between"}
          >
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "center",
                  padding: "20px",
                }}
              >
                <Typography
                  textAlign={"center"}
                  variant={"h3"}
                  sx={{
                    paddingTop: "10px",
                  }}
                >
                  {title}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <CardContent
            style={{
              padding: "0 20px 20px 20px",
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              maxHeight: "70vh",
            }}
          >
            {children}
            {(acceptLable || closeLabel) && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  flexDirection: "row",
                  paddingTop: 10,
                }}
              >
                {closeLabel && (
                  <Button
                    sx={{ marginRight: acceptLable ? 5 : 0 }}
                    size="small"
                    variant="text"
                    onClick={onClose}
                  >
                    {closeLabel}
                  </Button>
                )}
                {acceptLable && (
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={onAccept}
                  >
                    {acceptLable}
                  </Button>
                )}
              </div>
            )}
          </CardContent>
        </Card>
      </Box>
    </Modal>
  )
}

export default AUDialog
