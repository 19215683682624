import {
  AUArchiveSearchResultListItemDoc,
  AUSearchResultListItemDoc,
  DocsJSON,
  FilteredDocsJSON,
} from "../models"
import { DocJSON } from "./documentTranscriptParser"
import { AUSearchInArchiveResultsItemProps } from "../../SearchInArchive/AUSearchInArchiveResultsItem"
import { AUSearchInArchiveResultsTableCollectionListProps } from "../../SearchInArchive/AUSearchInArchiveResultsTable"

export interface FilteredMetadataJSON {
  document: DocJSON
  meta_key: string
  meta_headline: MetaHeadline
}

export interface MetaHeadline {
  tokens?: TokensEntity[] | null
}
export interface TokensEntity {
  text: string
  match: boolean
}

export interface ArchiveSearchResultItem {
  tokens?: TokensEntity[] | null
}
export interface TokensEntity {
  text: string
  match: boolean
}

export interface SearchRank {
  metric: number
}

export interface ArchiveSearchJSONContainer {
  search_results: ArchiveSearchJSON[]
  collections: AUSearchInArchiveResultsTableCollectionListProps[]
}

export interface ArchiveSearchObjectContainer {
  search_results: AUArchiveSearchResultListItemDoc[]
  collections: AUSearchInArchiveResultsTableCollectionListProps[]
}

export interface ArchiveSearchJSON {
  document: ArchiveSearchDocument
  results_transcript?: ArchiveSearchResultItem[]
  results_meta_key?: string | ArchiveSearchResultItem
  results_meta_value?: string | ArchiveSearchResultItem
  results_annot_key?: string | ArchiveSearchResultItem
  results_annot_value?: string | ArchiveSearchResultItem
  search_rank: SearchRank
}
export interface ArchiveSearchDocument {
  title: string
  language: string
  id: number
  file_type: string
  fk_collection: number
}

export interface Document {
  title: string
  language: string
  external_id: string
  id: number
  media_file_key: string
  peaks_file_key: string
  file_type: string
  fk_collection: number
}

export const makeListItemsLight = (
  fromJSON: DocsJSON[]
): AUSearchResultListItemDoc[] => {
  let resArr = new Array<AUSearchResultListItemDoc>()
  fromJSON.forEach((jsonItem) => {
    let listItem = {
      id: jsonItem.id,
      name: jsonItem.title,
      isaudio: jsonItem.file_type === "mp3",
    } as AUSearchResultListItemDoc

    resArr.push(listItem)
  })
  return resArr
}

export const makeListItemsForArchiveSearch = (
  fromJSON: ArchiveSearchJSONContainer
): ArchiveSearchObjectContainer => {
  let resArr = new Array<AUArchiveSearchResultListItemDoc>()

  fromJSON.search_results.forEach((jsonItem) => {
    var results = []

    let listItem = {
      id: jsonItem.document.id,
      order: jsonItem.search_rank.metric,
      collectionID: jsonItem.document.fk_collection,
      documentName: jsonItem.document.title,
      mediaType: jsonItem.document.file_type,
      isaudio: jsonItem.document.file_type === "mp3",
      transcriptResults: [],
    } as AUArchiveSearchResultListItemDoc

    if (jsonItem.results_transcript) {
      jsonItem.results_transcript.forEach((res, mainIndex) => {
        var str = ""
        res.tokens.forEach((token, index) => {
          if (token.match) {
            str += "<span class='au_found_word'>" + token.text + "</span>"
          } else {
            str += "" + token.text + ""
          }
        })

        let out = {
          resultType: "TYPE_TRANSCRIPT",
          position: mainIndex + 1,
          text: str,
          badgeText: null,
        } as AUSearchInArchiveResultsItemProps

        results.push(out)
      })
    }

    if (
      jsonItem.results_meta_value &&
      typeof jsonItem.results_meta_value === "string"
    ) {
      var strMeta = ""
      const meta = jsonItem.results_meta_key as ArchiveSearchResultItem
      meta.tokens.forEach((tokenMeta, index) => {
        if (tokenMeta.match) {
          strMeta +=
            "<span class='au_found_word_badge'>" + tokenMeta.text + "</span>"
        } else {
          strMeta += "" + tokenMeta.text + ""
        }
      })

      let out = {
        resultType: "TYPE_META",
        position: results.length + 1,
        text: jsonItem.results_meta_value,
        badgeText: strMeta,
      } as AUSearchInArchiveResultsItemProps

      results.push(out)
    }

    if (
      jsonItem.results_meta_key &&
      typeof jsonItem.results_meta_key === "string"
    ) {
      var strMeta = ""
      const meta = jsonItem.results_meta_value as ArchiveSearchResultItem
      meta.tokens.forEach((tokenMeta, index) => {
        if (tokenMeta.match) {
          strMeta += "<span class='au_found_word'>" + tokenMeta.text + "</span>"
        } else {
          strMeta += "" + tokenMeta.text + ""
        }
      })

      let out = {
        resultType: "TYPE_META",
        position: results.length + 1,
        text: strMeta,
        badgeText: jsonItem.results_meta_key,
      } as AUSearchInArchiveResultsItemProps

      results.push(out)
    }

    if (
      jsonItem.results_annot_value &&
      typeof jsonItem.results_annot_value === "string"
    ) {
      var strAnnotation = ""
      const annot = jsonItem.results_annot_key as ArchiveSearchResultItem
      annot.tokens.forEach((tokenAnnot, index) => {
        if (tokenAnnot.match) {
          strAnnotation +=
            "<span class='au_found_word_badge'>" + tokenAnnot.text + "</span>"
        } else {
          strAnnotation += "" + tokenAnnot.text + ""
        }
      })

      let out = {
        resultType: "TYPE_ANNOTATION",
        position: results.length + 1,
        text: jsonItem.results_annot_value,
        badgeText: strAnnotation,
      } as AUSearchInArchiveResultsItemProps

      results.push(out)
    }

    if (
      jsonItem.results_annot_key &&
      typeof jsonItem.results_annot_key === "string"
    ) {
      var strAnnotation = ""
      const annot = jsonItem.results_annot_value as ArchiveSearchResultItem
      annot.tokens.forEach((tokenAnnot, index) => {
        if (tokenAnnot.match) {
          strAnnotation +=
            "<span class='au_found_word'>" + tokenAnnot.text + "</span>"
        } else {
          strAnnotation += "" + tokenAnnot.text + ""
        }
      })

      let out = {
        resultType: "TYPE_ANNOTATION",
        position: results.length + 1,
        text: strAnnotation,
        badgeText: jsonItem.results_annot_key,
      } as AUSearchInArchiveResultsItemProps

      results.push(out)
    }

    listItem.transcriptResults = results

    resArr.push(listItem)
  })
  return { search_results: resArr, collections: fromJSON.collections }
}

export const makeListItems = (
  fromJSON: FilteredDocsJSON[]
): AUSearchResultListItemDoc[] => {
  let resArr = new Array<AUSearchResultListItemDoc>()
  fromJSON.forEach((jsonItem) => {
    let listItem = {
      id: jsonItem.document.id,
      name: jsonItem.document.title,
      isaudio: jsonItem.document.file_type === "mp3",
      headlines: jsonItem.headlines.map((hline) => {
        var string = ""
        hline.tokens.forEach((tk) => {
          if (tk.match) {
            string += "<span class='au_found_word'>" + tk.text + "</span>"
          } else {
            string += "" + tk.text + ""
          }
        })

        return string
      }),
    } as AUSearchResultListItemDoc

    resArr.push(listItem)
  })
  return resArr
}

export const makeListItemsMetadata = (
  fromJSON: FilteredMetadataJSON[]
): AUSearchResultListItemDoc[] => {
  let resArr = new Array<AUSearchResultListItemDoc>()
  fromJSON.forEach((jsonItem) => {
    let listItem = {
      id: jsonItem.document.id,
      name: jsonItem.document.title,
      isaudio: jsonItem.document.file_type === "mp3",
      headlines: [jsonItem.meta_headline].map((hline) => {
        var string = ""
        hline.tokens.forEach((tk) => {
          if (tk.match) {
            string += "<span class='au_found_word'>" + tk.text + "</span>"
          } else {
            string += "" + tk.text + ""
          }
        })

        return string
      }),
    } as AUSearchResultListItemDoc

    resArr.push(listItem)
  })
  return resArr
}
