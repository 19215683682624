import React, { useEffect, useState } from "react"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import { useRouter } from "next/router"
import {
  Alert,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
} from "@mui/material"
import { useTranslation } from "next-i18next"
import { MoreVert } from "@mui/icons-material"
import AUDialog from "./AUDialog"
import { Edit3, Trash } from "react-feather"
import theme from "./theme"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { useArchivesTableContext } from "./context/ArchivesTableContext"
import { useCollectionsTableContex } from "./context/CollectionsTableContext"
import { useDocumentsTableContex } from "./context/DocumentsTableContext"

export interface AUTableCellMenuProps {
  row: any
  menuItems: AUTableCellMenuItem[]
}

export enum AUTableCellMenuAction {
  DELETE_ARCHIVE,
  EDIT_ARCHIVE,
  DELETE_COLLECTION,
  EDIT_COLLECTION,
  DELETE_DOC,
  EDIT_DOC,
}

export interface AUTableCellMenuItem {
  action: AUTableCellMenuAction
  lable: string
}

const AUTableCellMenu = ({ row, menuItems }: AUTableCellMenuProps) => {
  const router = useRouter()
  const { archiveId, collectionId } = router.query
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const isMenuOpen = Boolean(anchorEl)
  const [isDeleteArchiveOpen, setIsDeleteArchiveOpen] = useState(false)
  const [isEditArchiveOpen, setIsEditArchiveOpen] = useState(false)
  const [isDeleteCollectionOpen, setIsDeleteCollectionOpen] = useState(false)
  const [isEditCollectionOpen, setIsEditCollectionOpen] = useState(false)
  const [isDeleteDocOpen, setIsDeleteDocOpen] = useState(false)
  const [isEditDocOpen, setIsEditDocOpen] = useState(false)
  const [selectedCell, setSelectedCell] = useState(null)
  const { deleteArchive } = useArchivesTableContext() as any
  const { deleteCollection } = useCollectionsTableContex() as any
  const { deleteDocument } = useDocumentsTableContex() as any

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClicked = (item) => {
    setSelectedCell(row)
    handleClose()
    switch (item.action) {
      case AUTableCellMenuAction.DELETE_ARCHIVE:
        setIsDeleteArchiveOpen(true)
        break
      case AUTableCellMenuAction.EDIT_ARCHIVE:
        setIsEditArchiveOpen(true)
        break
      case AUTableCellMenuAction.DELETE_COLLECTION:
        setIsDeleteCollectionOpen(true)
        break
      case AUTableCellMenuAction.EDIT_COLLECTION:
        setIsEditCollectionOpen(true)
        break
      case AUTableCellMenuAction.DELETE_DOC:
        setIsDeleteDocOpen(true)
        break
      case AUTableCellMenuAction.EDIT_DOC:
        setIsEditDocOpen(true)
        break
      default:
        console.log(row)
    }
  }

  const deleteDoc = (docId) => {
    setIsDeleteDocOpen(false)
    deleteDocument(collectionId, docId)
  }

  const deleteArch = (archId) => {
    setIsDeleteArchiveOpen(false)
    deleteArchive(archId)
  }

  const deleteColl = (collId) => {
    setIsDeleteCollectionOpen(false)
    deleteCollection(archiveId, collId)
  }

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        size="small"
        sx={{ marginLeft: 3 }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMenuOpen}
        onClose={handleClose}
      >
        {/*<MenuItem disabled onClick={handleClose}>
                        {email}
                      </MenuItem>
                      <MenuItem disabled onClick={handleClose}>
                        Version {appVersion}
                    </MenuItem>*/}
        {menuItems &&
          menuItems.map((item, index) => (
            <MenuItem key={index} onClick={() => handleMenuItemClicked(item)}>
              {item.lable}
            </MenuItem>
          ))}
      </Menu>

      <AUDialog
        title={t("delete_archive_dialog_title")}
        open={isDeleteArchiveOpen}
        icon={
          <Trash
            fontWeight={800}
            size={26}
            color={theme.palette.primary.main}
          />
        }
        onClose={() => setIsDeleteArchiveOpen(false)}
        onAccept={() => deleteArch(selectedCell?.id)}
        acceptLable={t("delete_button")}
        closeLabel={t("cancel_button")}
      >
        <Typography component="span" variant="body2">
          {t("delete_archive_dialog_description", { name: selectedCell?.name })}
        </Typography>
      </AUDialog>

      <EditArchiveDialog
        open={isEditArchiveOpen}
        onClose={() => setIsEditArchiveOpen(false)}
        onAccept={() => console.log("Save")}
        selectedCell={selectedCell}
      ></EditArchiveDialog>

      <AUDialog
        title={t("delete_collection_dialog_title")}
        open={isDeleteCollectionOpen}
        icon={
          <Trash
            fontWeight={800}
            size={26}
            color={theme.palette.primary.main}
          />
        }
        onClose={() => setIsDeleteCollectionOpen(false)}
        onAccept={() => deleteColl(selectedCell?.id)}
        acceptLable={t("delete_button")}
        closeLabel={t("cancel_button")}
      >
        <Typography component="span" variant="body2">
          {t("delete_collection_dialog_description", {
            name: selectedCell?.name,
          })}
        </Typography>
      </AUDialog>

      <EditCollectionDialog
        open={isEditCollectionOpen}
        onClose={() => setIsEditCollectionOpen(false)}
        onAccept={() => console.log("save")}
        selectedCell={selectedCell}
      ></EditCollectionDialog>

      <AUDialog
        title={t("delete_doc_dialog_title")}
        open={isDeleteDocOpen}
        icon={
          <Trash
            fontWeight={800}
            size={26}
            color={theme.palette.primary.main}
          />
        }
        onClose={() => setIsDeleteDocOpen(false)}
        onAccept={() => deleteDoc(selectedCell?.id)}
        acceptLable={t("delete_button")}
        closeLabel={t("cancel_button")}
      >
        <Typography component="span" variant="body2">
          {t("delete_doc_dialog_description", { name: selectedCell?.name })}
        </Typography>
      </AUDialog>

      <EditDocDialog
        open={isEditDocOpen}
        onClose={() => setIsEditDocOpen(false)}
        onAccept={() => console.log("save")}
        selectedCell={selectedCell}
      ></EditDocDialog>
    </>
  )
}

const EditArchiveDialog = ({ open, onClose, onAccept, selectedCell }) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { editArchive } = useArchivesTableContext() as any
  const [showNameAlreadyUsedError, setShowNameAlreadyUsedError] =
    useState(false)

  const validationEditArchiveSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("create_archive_name_field_empty_error"))
      .max(40, t("create_archive_name_field_max_length_error")),
  })

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationEditArchiveSchema),
  })

  useEffect(() => {
    setShowNameAlreadyUsedError(false)
    reset({
      name: selectedCell?.name,
    })
  }, [open])

  const updateName = (data) => {
    setShowNameAlreadyUsedError(false)
    editArchive(selectedCell?.id, data.name)
      .then((res) => {
        onClose()
      })
      .catch((error) => {
        if (error && error.response.status == 500) {
          setShowNameAlreadyUsedError(true)
        } else {
          console.log(error)
        }
      })
  }

  return (
    <AUDialog
      title={t("edit_archive_dialog_title")}
      open={open}
      icon={
        <Edit3 fontWeight={800} size={26} color={theme.palette.primary.main} />
      }
      onClose={onClose}
      onAccept={handleSubmit(updateName)}
      acceptLable={t("save_button")}
      closeLabel={t("close_button")}
    >
      <Typography component="span" variant="body2">
        {t("edit_archive_dialog_description")}
      </Typography>
      <FormControl
        sx={{
          fontFamily: "Libre Franklin",
          marginTop: "14px",
        }}
        variant="outlined"
        fullWidth
        required
      >
        <InputLabel htmlFor="email">
          {t("create_archive_name_field")}
        </InputLabel>
        <OutlinedInput
          required
          id="name"
          {...register("name")}
          error={errors.name ? true : false}
          label={t("create_archive_name_field")}
        />
        <FormHelperText error={errors.name != null} id="accountId-error">
          {errors.name?.message}
        </FormHelperText>
      </FormControl>
      {showNameAlreadyUsedError && (
        <Alert sx={{ marginTop: 1 }} severity="error">
          {t("error_archive_with_name_exists")}
        </Alert>
      )}
    </AUDialog>
  )
}

const EditCollectionDialog = ({ open, onClose, onAccept, selectedCell }) => {
  const router = useRouter()
  const { archiveId } = router.query
  const { t } = useTranslation()
  const { editCollection } = useCollectionsTableContex() as any
  const [showNameAlreadyUsedError, setShowNameAlreadyUsedError] =
    useState(false)

  const validationEditArchiveSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("create_collection_name_field_empty_error"))
      .max(40, t("create_collection_name_field_max_length_error")),
  })

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationEditArchiveSchema),
  })

  useEffect(() => {
    reset({
      name: selectedCell?.name,
    })
    setShowNameAlreadyUsedError(false)
  }, [open])

  const updateName = (data) => {
    setShowNameAlreadyUsedError(false)
    editCollection(archiveId, selectedCell?.id, data.name)
      .then((res) => {
        onClose()
      })
      .catch((error) => {
        if (error && error.response.status == 500) {
          setShowNameAlreadyUsedError(true)
        } else {
          console.log(error)
        }
      })
  }

  return (
    <AUDialog
      title={t("edit_collection_dialog_title")}
      open={open}
      icon={
        <Edit3 fontWeight={800} size={26} color={theme.palette.primary.main} />
      }
      onClose={onClose}
      onAccept={handleSubmit(updateName)}
      acceptLable={t("save_button")}
      closeLabel={t("close_button")}
    >
      <Typography component="span" variant="body2">
        {t("edit_collection_dialog_description")}
      </Typography>
      <FormControl
        sx={{
          fontFamily: "Libre Franklin",
          marginTop: "14px",
        }}
        variant="outlined"
        fullWidth
        required
      >
        <InputLabel htmlFor="email">
          {t("create_archive_name_field")}
        </InputLabel>
        <OutlinedInput
          required
          id="name"
          {...register("name")}
          error={errors.name ? true : false}
          label={t("create_archive_name_field")}
        />
        <FormHelperText error={errors.name != null} id="accountId-error">
          {errors.name?.message}
        </FormHelperText>
      </FormControl>
      {showNameAlreadyUsedError && (
        <Alert sx={{ marginTop: 1 }} severity="error">
          {t("error_collection_with_name_exists")}
        </Alert>
      )}
    </AUDialog>
  )
}

const EditDocDialog = ({ open, onClose, onAccept, selectedCell }) => {
  const router = useRouter()
  const { collectionId } = router.query
  const { t } = useTranslation()
  const { editDocName } = useDocumentsTableContex() as any

  const validationEditArchiveSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("create_collection_name_field_empty_error"))
      .max(40, t("create_collection_name_field_max_length_error")),
  })

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationEditArchiveSchema),
  })

  useEffect(() => {
    reset({
      name: selectedCell?.name,
    })
  }, [selectedCell])

  const updateName = (data) => {
    onClose()
    editDocName(collectionId, selectedCell?.id, data.name)
  }

  return (
    <AUDialog
      title={t("edit_doc_dialog_title")}
      open={open}
      icon={
        <Edit3 fontWeight={800} size={26} color={theme.palette.primary.main} />
      }
      onClose={onClose}
      onAccept={handleSubmit(updateName)}
      acceptLable={t("save_button")}
      closeLabel={t("close_button")}
    >
      <Typography component="span" variant="body2">
        {t("edit_doc_dialog_description")}
      </Typography>
      <FormControl
        sx={{
          fontFamily: "Libre Franklin",
          marginTop: "14px",
        }}
        variant="outlined"
        fullWidth
        required
      >
        <InputLabel htmlFor="email">
          {t("create_archive_name_field")}
        </InputLabel>
        <OutlinedInput
          required
          id="name"
          {...register("name")}
          error={errors.name ? true : false}
          label={t("create_archive_name_field")}
        />
        <FormHelperText error={errors.name != null} id="accountId-error">
          {errors.name?.message}
        </FormHelperText>
      </FormControl>
    </AUDialog>
  )
}

export default AUTableCellMenu
