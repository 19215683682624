import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import React, { createContext, useContext, useEffect, useState } from "react"
import {
  deleteCollectionById,
  getArchives,
  getCollections,
  getErrorInfo,
  updateArchiveName,
  updateCollection,
} from "../utils/api/aurekaAPI"

export const CollectionsTableContext = createContext({})

export const CollectionsTableContextProvider = ({ children }) => {
  const [collections, setCollections] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const router = useRouter()
  const { archiveId } = router.query
  const { t } = useTranslation()

  useEffect(() => {
    if (archiveId === undefined) return
    fetchColls(archiveId)
  }, [archiveId])

  const setCurrentCollections = (collections) => {
    setCollections(collections)
  }

  const deleteCollection = (archiveId, collectionId) => {
    setIsLoading(true)
    deleteCollectionById(archiveId, collectionId)
      .then(() => {
        fetchColls(archiveId)
      })
      .catch((error) => console.log(error))
  }

  const editCollection = async (archiveId, collectionId, collectionName) => {
    let res
    try {
      setIsLoading(true)
      res = await updateCollection(archiveId, collectionId, collectionName)
      fetchColls(archiveId)
      return res
    } catch (error) {
      throw error
    }
  }

  const fetchColls = (archiveId) => {
    setIsLoading(true)
    getCollections(archiveId)
      .then((res) => {
        setCollections(res.data)
        setIsLoading(false)
      })
      .catch(function (error) {
        if (error.response.status == 404) {
          router.push("/archives")
        } else {
          const message = getErrorInfo(error, t)
          console.log(message)
        }
        setIsLoading(false)
      })
  }

  return (
    <CollectionsTableContext.Provider
      value={{
        collections,
        isLoading,
        setCurrentCollections,
        deleteCollection,
        editCollection,
      }}
    >
      {children}
    </CollectionsTableContext.Provider>
  )
}

export const useCollectionsTableContex = () =>
  useContext(CollectionsTableContext)
