import { useTranslation } from "next-i18next"
import React, { createContext, useContext, useEffect, useState } from "react"
import {
  deleteArchiveById,
  getArchives,
  getErrorInfo,
  updateArchiveName,
} from "../utils/api/aurekaAPI"

export const ArchivesTableContext = createContext({})

export const ArchivesTableContextProvider = ({ children }) => {
  const [archives, setArchives] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()

  useEffect(() => {
    fetchArchives()
  }, [])

  const setCurrentArchives = (archives) => {
    setArchives(archives)
  }

  const deleteArchive = (archiveId) => {
    setIsLoading(true)
    deleteArchiveById(archiveId)
      .then(() => {
        fetchArchives()
      })
      .catch((error) => console.log(error))
  }

  const fetchArchives = () => {
    setIsLoading(true)
    getArchives()
      .then((res) => {
        setCurrentArchives(res.data)
        setIsLoading(false)
      })
      .catch(function (error) {
        setIsLoading(false)
        const message = getErrorInfo(error, t)
        console.log(message)
      })
  }

  const editArchive = async (archiveId, newName) => {
    let res
    try {
      setIsLoading(true)
      res = await updateArchiveName(archiveId, newName)
      fetchArchives()
      return res
    } catch (error) {
      throw error
    }
  }

  return (
    <ArchivesTableContext.Provider
      value={{
        archives,
        isLoading,
        setCurrentArchives,
        deleteArchive,
        editArchive,
      }}
    >
      {children}
    </ArchivesTableContext.Provider>
  )
}

export const useArchivesTableContext = () => useContext(ArchivesTableContext)
