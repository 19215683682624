import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import React, { createContext, useContext, useEffect, useState } from "react"
import {
  deleteDoc,
  getDocs,
  getErrorInfo,
  updateDocTitle,
} from "../utils/api/aurekaAPI"
import { makeListItemsLight } from "../utils/json-parsers/documentListParser"

export const DocumentsTableContext = createContext({})

export const DocumentsTableContextProvider = ({ children }) => {
  const [docs, setDocs] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const router = useRouter()
  const { collectionId } = router.query
  const { t } = useTranslation()

  useEffect(() => {
    if (collectionId === undefined) return
    fetchDocs(collectionId)
  }, [collectionId])

  const setCurrentDocs = (docs) => {
    let r = makeListItemsLight(docs)
    r.sort((a, b) => a.name.localeCompare(b.name))
    setDocs(r)
  }

  const deleteDocument = (collectionId, docId) => {
    setIsLoading(true)
    deleteDoc(collectionId, docId)
      .then(() => {
        fetchDocs(collectionId)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const editDocName = (collectionId, docId, name) => {
    setIsLoading(true)
    updateDocTitle(collectionId, docId, name)
      .then(() => {
        fetchDocs(collectionId)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const fetchDocs = (collId) => {
    setIsLoading(true)
    getDocs(collId)
      .then((res) => {
        let r = makeListItemsLight(res.data)
        r.sort((a, b) => a.name.localeCompare(b.name))
        setDocs(r)
        setIsLoading(false)
      })
      .catch((error) => {
        if (error.response.status == 404) {
          router.push("/archives")
        } else {
          const message = getErrorInfo(error, t)
          console.log(message)
        }
      })
  }

  return (
    <DocumentsTableContext.Provider
      value={{ docs, isLoading, setCurrentDocs, deleteDocument, editDocName }}
    >
      {children}
    </DocumentsTableContext.Provider>
  )
}

export const useDocumentsTableContex = () => useContext(DocumentsTableContext)
